<template>
  <div>
    <Card :bordered="false" dis-hover>
      <Form
        ref="formValidate"
        :model="searchWhere"
        :label-width="80"
        label-position="right"
        @submit.native.prevent>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="搜索：">
              <div class="acea-row row-middle">
                <Input
                  search
                  enter-button
                  @on-search="handleSelectChange"
                  placeholder="请输入语言Code"
                  element-id="name"
                  v-model="searchWhere.keyword"
                  style="width: 30%"
                />
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
    <Card :bordered="false" dis-hover>
      <Row type="flex">
        <Col v-bind="grid">
          <Button type="primary" icon="md-add" @click="handleCreate">添加语言地区</Button>
        </Col>
      </Row>
      <Table
        ref="table"
        :columns="columns"
        :data="countryList"
        class="ivu-mt l-m-t25"
        :loading="loading"
        no-data-text="暂无数据"
        no-filtered-data-text="暂无筛选结果">
        <template slot-scope="{ row, index }" slot="action">
          <a @click="handleEdit(row)">编辑</a>
          <Divider type="vertical" />
          <a class="l-color-error" @click="handleDelete(row, '删除地区语言', index)">删除</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit"/>
      </div>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getLangCountryListApi,
  getLangCountryFormApi
} from '@/api/system'

export default {
  name: 'country',
  mixins: [mixins],
  data () {
    return {
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 15,
        keyword: ''
      },
      // 表格数据
      countryList: [],
      columns: [
        {
          title: '编号',
          key: 'id',
          width: 120
        },
        {
          title: '浏览器语言识别码',
          key: 'code',
          minWidth: 150
        },
        {
          title: '语言说明',
          key: 'name',
          minWidth: 180
        },
        {
          title: '关联语言',
          key: 'link_lang',
          minWidth: 180
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 120
        }
      ],
      code: null
    }
  },
  mounted () {
    this.getLangCountryListRequest()
  },
  methods: {
    // 获取列表
    getLangCountryListRequest () {
      this.loading = true
      getLangCountryListApi(this.searchWhere).then(res => {
        this.loading = false
        this.countryList = res.data.list
        this.total = res.data.count
      }).catch(err => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 处理选择搜索
    handleSelectChange () {
      this.searchWhere.page = 1
      this.getLangCountryListRequest()
    },
    // 创建
    handleCreate () {
      this.$modalForm(getLangCountryFormApi(0)).then(() => this.getLangCountryListRequest())
    },
    // 编辑
    handleEdit (row) {
      this.$modalForm(getLangCountryFormApi(row.id)).then(() => this.getLangCountryListRequest())
    },
    // 删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: `system/lang/country/del/${row.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$modalSure(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.tabList.splice(num, 1)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 分页搜索
    handlePageChange (index) {
      this.searchWhere.page = index
      this.getLangCountryListRequest()
    }
  }
}
</script>

<style scoped>

</style>
